import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { SINGLE_TONE_MODULES } from './constants';
import { ensureModuleLoadedOnceGuard } from './guards';
import { ErrorInterceptor, JwtInterceptor, UnauthorizedInterceptor } from './interceptors';
import { AuthService, ClipboardService, EncryptionService, FileManagementService, QueryParamsMapperService, ThemeService } from './services';
import { HttpService } from './services/http.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { jwtCredentials } from './signals';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEn } from './adapters';
import { Credentials } from './dtos';
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && control.dirty);
  }
}
function authInitializer(authService:AuthService): Promise<boolean> {
  return new Promise((resolve) => {
    const credentials = authService.restoreCredentials() as Credentials;
    if (credentials) {
      jwtCredentials.set({accessToken:credentials.accessToken, refreshToken:credentials.refreshToken})
      resolve(true);
    } else {
      jwtCredentials.set(null)
      resolve(false);
    }
  });
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ThemeService,
    HttpService,
    AuthService,
    FileManagementService,
    ClipboardService,
    QueryParamsMapperService,
    EncryptionService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['app-snackbar', 'app-mt-100'],
      } as MatSnackBarConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (autService:AuthService) => () => authInitializer(autService),
      multi: true,
      deps:[AuthService]
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEn
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    ensureModuleLoadedOnceGuard(parentModule, SINGLE_TONE_MODULES.CORE_MODULE);
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}

