import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSnackBarService } from '@core/services';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackbar: AppSnackBarService) { }

  intercept(request: HttpRequest<never>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred';

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // Server-side error
          errorMessage = `(status code ${error.status}): ${error.message}`;
          if (Array.isArray(error.error?.error?.details) && error.error.error.details[0]?.message) {
            errorMessage = `${error.error.error.details[0].message}`;
          }
        }

        this.snackbar.error(errorMessage)

        return throwError(() => error);
      })
    );
  }
}
