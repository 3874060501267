import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JWT } from '@core/models';
import { AuthService } from '@core/services';
import { jwtCredentials } from '@core/signals';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (jwtCredentials()?.refreshToken) {
            return this.tryToGetNewAccessToken(request, next);
          }
          this.authService.logout();
        }
        return throwError(() => error);
      })
    );
  }

  private tryToGetNewAccessToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null); // Start of refresh process

      return from(this.authService.getRefreshToken()).pipe(
        switchMap((jwtToken: JWT | null) => {
          if (jwtToken?.accessToken) {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(jwtToken.accessToken); // Emit new token
            return next.handle(this.addTokenHeader(request, jwtToken.accessToken));
          }
          this.authService.logout();
          return throwError(() => 'Unauthorized access token');
        }),
        catchError(err => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(() => `Error during refresh token. ${err}`);
        })
      );
    } else {
      // Wait for new token to be emitted
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token!)))
      );
    }
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` }
    });
  }
}
