import { provideRouter, Routes, withComponentInputBinding, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { authGuard, loginGuard } from '@core/guards';
import { BreadCrumb } from '@core/models';
import { LoginComponent } from '@views';
import { environment } from 'env';
const AppRoutes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'cpl' },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [loginGuard]
    },

    {
        path: 'cpl',
        loadChildren: () => import('./views/cpl/cpl.routes').then(x => x.CPL_ROUTES),
        canActivate: [authGuard],
        data: {
            breadcrumb: {
                icon: 'fa-regular fa-house',
                iconOnly: true,
                extraIconStyleClass: 'material-icons-outlined',
            } as BreadCrumb,
        },
    },

];

if (!environment.production) {
    AppRoutes.push({
        path: 'cp',
        loadChildren: () => import('@dev-tools').then(x => x.COMPONENT_PLAYGROUND_ROUTES),
    })
}

// app.route rewriting the router providers using provideRouter
export const AppRouteProviders = [
    provideRouter(AppRoutes,
        // this is in place of scrollPositionRestoration: 'disabled',
        withInMemoryScrolling({
            scrollPositionRestoration: 'disabled',
        }),
        withComponentInputBinding(),
        // in place of initialNavigation: 'enabledBlocking'
        withEnabledBlockingInitialNavigation(),
        // same configuration
        withRouterConfig({
            paramsInheritanceStrategy: 'always',
            onSameUrlNavigation: 'reload'
        }),
        // in place of  preloadingStrategy: PreloadService,
        //   withPreloading(PreloadService),
    ),
];