import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { MaterialIconRegisteryService, ThemeService } from '@core/services';
import { SidenavComponent, SidePanelComponent } from '@shared/layout';
import { MatListModule } from '@angular/material/list';
import {
  MatBottomSheet,
} from '@angular/material/bottom-sheet';
import { environment } from 'env'; 
import { DevToolSheetComponent } from '@dev-tools';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SidePanelComponent, SidenavComponent, MatButtonModule, MatIconModule, MatListModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'y-project';
  themeService = inject(ThemeService);
  isProductionMode = environment.production;
  _materialIconRegistery = inject(MaterialIconRegisteryService);
  _bottomSheet = inject(MatBottomSheet)
  constructor() {
    this.themeService.syncWithUserPreferedTheme();
  }
  ngOnInit(): void {
    this._materialIconRegistery.registerIcons();
  }

  openBottomSheet(): void {
    this._bottomSheet.open(DevToolSheetComponent);
  }
}